<template>
  <TTView>
    <VRow>
      <VCol>
        <PositionCard
          :companies="companies"
          :position="position"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VCard>
          <VCardTitle> Пользователей в позиции: {{ usersCount }} </VCardTitle>

          <VDivider />

          <VCardText>
            <VContainer
              class="py-0"
              fluid
            >
              <VRow>
                <VCol
                  class="pt-0"
                  md="12"
                >
                  <VCardTitle class="pt-0">
                    Поиск по UUID
                  </VCardTitle>

                  <VTextField
                    v-model="uuid"
                    solo
                    outlined
                    flat
                    placeholder="UUID"
                  />

                  <VBtn
                    color="primary"
                    @click="getUser"
                  >
                    Найти
                  </VBtn>
                </VCol>
              </VRow>

              <VRow>
                <VCol md="12">
                  <VCardTitle class="pt-0">
                    Поиск по логину
                  </VCardTitle>

                  <VTextField
                    v-model="login"
                    solo
                    outlined
                    flat
                    placeholder="Логин"
                  />

                  <VSelect
                    v-model="type"
                    :items="types"
                    solo
                    outlined
                    flat
                  />

                  <VBtn
                    color="primary"
                    @click="getUserIDByLogin"
                  >
                    Найти
                  </VBtn>
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <div v-if="user">
                    <div><strong>Пользователь</strong></div>
                    <div>Активный: {{ user.active }}</div>
                    <h3 class="pt-4">
                      Аккаунты
                    </h3>
                    <div class="py-4">
                      <div
                        v-for="acc in user.accounts"
                        :key="acc.type"
                        class="d-flex"
                      >
                        <div class="mr-4">
                          Логин:{{ acc.login }}
                        </div>
                        <div>Тип: {{ acc.type }}</div>
                      </div>
                    </div>

                    <VTooltip
                      v-if="!userHasPosition"
                      left
                    >
                      <template #activator="{ on, attrs }">
                        <VBtn
                          icon
                          small
                          dark
                          exact
                          color="green"
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="addUser"
                        >
                          <VIcon small>
                            fal fa-plus
                          </VIcon>
                        </VBtn>
                      </template>

                      <span>Добавить</span>
                    </VTooltip>

                    <VTooltip
                      v-if="userHasPosition"
                      left
                    >
                      <template #activator="{ on, attrs }">
                        <VBtn
                          icon
                          small
                          dark
                          exact
                          color="red"
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="removeUser"
                        >
                          <VIcon small>
                            fal fa-minus
                          </VIcon>
                        </VBtn>
                      </template>

                      <span>Удалить</span>
                    </VTooltip>
                  </div>
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_POSITION } from '../../components/competency-matrix/positions/common';
import PositionCard from '../../components/competency-matrix/positions/PositionCard.vue';

const authTypes = {
  EMAIL: 'TYPE_EMAIL',
  LOGIN: 'TYPE_LOGIN',
  SAP: 'TYPE_SAP',
  PHONE: 'TYPE_PHONE',
  INVALID: 'TYPE_INVALID',
};

export default {
  name: 'PositionUsers',

  components: {
    PositionCard,
  },

  data() {
    return {
      authTypes,
      companies: [],
      loading: false,
      position: { ...DEFAULT_POSITION },
      usersCount: 0,
      user: null,
      userMatrix: {},
      uuid: '',
      login: '',
      type: authTypes.PHONE,
      types: [
        {
          text: 'Инвалид',
          value: authTypes.INVALID,
        },
        {
          text: 'Логин',
          value: authTypes.LOGIN,
        },
        {
          text: 'Email',
          value: authTypes.EMAIL,
        },
        {
          text: 'Телефон',
          value: authTypes.PHONE,
        },
        {
          text: 'САП',
          value: authTypes.SAP,
        },
      ],
    };
  },

  computed: {
    userHasPosition() {
      if (!this.userMatrix.position) return false;

      const { id: positionId = -1 } = this.userMatrix.position;
      return positionId === this.$route.params.positionId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const data = { id };
        const [companiesResponse, positionResponse, usersCountResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.PositionsGet(data),
          this.$di.api.Orgstructure.positionsUserCount({ competency_group_id: id }),
        ]);

        this.companies = companiesResponse.companies || [];
        this.position = positionResponse.position || { ...DEFAULT_POSITION };
        this.usersCount = usersCountResponse.count || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },

    getUser() {
      return this.$di.api.Authentication.getUser({ id: this.uuid })
        .then((r) => {
          this.user = r;
        })
        .then(() => {
          this.userMatrix = {};
          return this.$di.api.CompetencyMatrix.PositionsUserMatrix({
            userId: this.uuid,
            companyId: this.position.companyId,
          });
        })
        .then((r) => {
          this.userMatrix = r;
        })
        .catch(this.$di.notify.errorHandler);
    },

    getUserIDByLogin() {
      return this.$di.api.Authentication.getUserIDByLogin({ login: this.login, type: this.type })
        .then((r) => {
          this.uuid = r.id;
        })
        .then(() => this.getUser())
        .catch(this.$di.notify.errorHandler);
    },

    async addUser() {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const userId = this.uuid;
        const data = { id, userId };

        await this.$di.api.CompetencyMatrix.PositionsAddUser(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
      await this.getUser();
    },

    async removeUser() {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const userId = this.uuid;
        const data = { id, userId };

        await this.$di.api.CompetencyMatrix.PositionsRemoveUser(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
      await this.getUser();
    },
  },
};
</script>
